.about__section-image{
    overflow: hidden;
    transition: var(--transition);
}

.about__section-content h1{
    margin-bottom: 2rem;
}

.about__section-content p{
    margin-bottom: 1rem;
}

.about__story-container, .about__mission-container{
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}

.about__vision-container{
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 10%
}

/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
    .about__section-image{
        width: 60%;
    }

    .about__section-content h1{
        margin-bottom: 1.2rem;
    }

    .about__story-container, .about__vision-container, .about__mission-container{
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .about__story-container .about__section-image, .about__mission-container .about__section-image {
        grid-row: 2;
    }
}




/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
    .about__section-image{
        width: 80%;
        margin-inline: auto;
    }

    .about__story-container, .about__vision-container, .about__mission-container{
        gap: 1rem;
    }


}












.videowrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}